export const Footer = () => {
  return (
    <footer className="section-footer wf-section mt-10">
      <div className="cards">
        <div className="footer-content-wrapper">
          <div className="footer-brand">
            <a href="/" className="footer-logo-mark w-inline-block">
              <img src="https://mazze.io/images/mazzeblockchain.svg" loading="lazy" width="200" alt="Mazze Blockchain Logo" />
            </a>
            <div className="hq">
              <div>Building the Future on Blockchain<br />Fast. Secure. Private.</div>
            </div>
            <div className="legal-details">
              <a href="https://mazze.notion.site/mazze/Help-Center-4a6bfb6441054be8a76d60a90c053d88" target="_blank">Help Center</a>
              <div className="dot">·</div>
              <a href="https://mazze.notion.site/Terms-of-Service-477f88bab29e4fbcb6671fa27909a32a" target="_blank">Terms of Service</a>
              <div className="dot">·</div>
              <a href="mailto:legal@mazze.io" target="_blank">Legal</a>
              <div className="dot">·</div>
              <a href="mailto:contact@mazze.io" target="_blank">Contact</a>
            </div>
          </div>
          <div className="footer-links">
            <div id="w-node-_603090ae-70ed-3fda-969e-e2d72e80c211-f629c98b" className="footer-menu">
              <a href="#" className="nav-link w-inline-block">
                <div>Home</div>
              </a>
              <div className="footer-line-small"></div>
              <a href="https://docs.mazze.io/use/get-started" target="_blank" className="nav-link w-inline-block">
                <div>Get started</div>
              </a>
              <div className="footer-line-small"></div>
              <a href="#" className="nav-link w-inline-block">
                <div>Legal</div>
              </a>
              <div className="footer-line-small"></div>
              <a href="#" className="nav-link w-inline-block">
                <div>Log in</div>
              </a>
            </div>
            <div className="social-links">
              <div className="w-layout-grid footer-social-grid">
                <a href="https://x.com/MazzeLabs" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                      <path fill="#a3a7bf" d="
                        M 242.88 0.00
                        L 257.26 0.00
                        Q 356.54 4.43 426.02 72.34
                        Q 433.22 79.38 439.69 86.94
                        Q 496.08 152.90 500.00 241.38
                        L 500.00 262.75
                        Q 498.70 277.94 497.55 285.75
                        C 488.36 348.08 455.91 405.21 407.31 444.33
                        Q 342.30 496.65 257.37 500.00
                        L 242.47 500.00
                        Q 147.11 495.71 78.65 431.97
                        C 38.85 394.92 12.96 345.58 3.50 292.34
                        Q 2.26 285.38 1.42 277.31
                        Q 0.67 270.09 0.00 262.87
                        L 0.00 241.26
                        Q 3.82 152.91 60.19 86.97
                        Q 92.14 49.59 137.64 26.69
                        Q 182.46 4.12 232.63 0.73
                        Q 237.75 0.39 242.88 0.00
                        Z
                        M 238.29 294.24
                        L 317.02 393.00
                        Q 317.49 393.60 318.25 393.60
                        L 418.24 393.60
                        Q 418.84 393.60 418.92 393.00
                        Q 418.98 392.59 418.72 392.25
                        Q 354.22 310.52 290.00 229.13
                        A 0.67 0.66 -43.2 0 1 290.03 228.27
                        L 399.22 108.69
                        A 0.70 0.70 0.0 0 0 398.70 107.51
                        L 349.97 107.51
                        Q 349.34 107.51 348.93 107.97
                        L 266.48 198.27
                        Q 266.07 198.72 265.69 198.24
                        L 194.18 107.84
                        A 0.84 0.83 -18.8 0 0 193.52 107.52
                        L 90.74 107.52
                        A 0.78 0.78 0.0 0 0 90.13 108.79
                        L 213.86 263.75
                        A 0.33 0.33 0.0 0 1 213.85 264.18
                        L 96.78 392.36
                        A 0.73 0.73 0.0 0 0 97.32 393.59
                        L 146.23 393.60
                        Q 146.68 393.60 146.99 393.26
                        L 237.45 294.21
                        Q 237.89 293.74 238.29 294.24
                        Z"></path>
                      <path fill="#a3a7bf" d="
                        M 178.76 135.51
                        L 358.74 363.43
                        A 0.69 0.69 0.0 0 1 358.20 364.55
                        L 332.08 364.40
                        A 0.69 0.69 0.0 0 1 331.54 364.14
                        L 149.51 136.09
                        A 0.69 0.69 0.0 0 1 150.05 134.97
                        L 178.23 135.25
                        A 0.69 0.69 0.0 0 1 178.76 135.51
                        Z"></path>
                    </svg>
                  </div>
                  <div>Twitter</div>
                </a>
                <a href="https://t.me/MazzeLabs" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                    <path fill="#a3a7bf" d="
                      M 242.65 0.00
                      L 257.50 0.00
                      Q 355.41 4.47 424.36 70.99
                      Q 431.59 77.97 438.13 85.49
                      Q 496.08 152.19 500.00 242.39
                      L 500.00 257.75
                      Q 499.55 263.62 499.10 269.49
                      Q 495.21 320.15 471.80 365.12
                      Q 449.25 408.44 413.57 439.09
                      Q 346.88 496.36 257.37 500.00
                      L 242.50 500.00
                      Q 149.85 495.94 82.31 435.31
                      Q 74.88 428.63 68.05 421.31
                      Q 4.32 353.02 0.00 257.62
                      L 0.00 242.25
                      Q 4.14 147.83 66.94 79.71
                      Q 98.28 45.71 141.80 24.56
                      Q 188.44 1.90 242.65 0.00
                      Z
                      M 230.29 343.66
                      Q 267.36 371.50 304.99 399.76
                      C 313.65 406.26 326.78 410.02 333.48 398.60
                      Q 336.48 393.49 337.58 388.25
                      Q 363.30 266.06 388.56 146.09
                      C 390.66 136.14 392.60 119.32 378.73 117.33
                      C 374.07 116.66 368.33 118.04 363.96 119.73
                      Q 210.87 178.89 57.70 238.16
                      Q 51.89 240.40 48.26 245.13
                      C 42.64 252.45 48.87 259.03 55.71 261.20
                      Q 93.14 273.05 132.07 285.55
                      A 1.15 1.13 -52.1 0 0 133.00 285.43
                      Q 228.80 226.05 324.51 166.75
                      C 327.14 165.13 333.17 162.64 336.19 164.68
                      A 1.82 1.81 -58.7 0 1 336.76 167.05
                      Q 335.59 169.20 333.65 170.97
                      Q 252.95 244.27 171.45 318.26
                      Q 171.04 318.63 170.99 319.18
                      Q 168.64 348.63 166.10 379.52
                      Q 165.96 381.23 166.90 382.71
                      C 171.50 389.96 179.39 391.14 185.70 385.14
                      Q 207.17 364.75 229.38 343.71
                      Q 229.81 343.30 230.29 343.66
                      Z"></path>
                  </svg>
                  </div>
                  <div>Telegram</div>
                </a>
                <a href="https://github.com/MazzeLabs" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                    <path fill="#a3a7bf" d="
                      M 500.00 241.88
                      L 500.00 274.21
                      Q 498.07 289.23 495.18 303.97
                      Q 483.83 361.87 448.65 407.87
                      C 430.01 432.24 406.20 452.98 379.67 469.87
                      Q 371.89 474.82 361.75 479.50
                      Q 349.04 485.38 337.19 490.06
                      C 329.82 492.97 318.07 495.72 313.16 487.90
                      C 310.84 484.21 310.78 479.06 311.01 474.69
                      C 311.44 466.78 311.20 459.09 311.39 450.98
                      Q 311.69 438.02 311.40 424.95
                      C 311.21 416.17 311.45 407.54 310.28 399.06
                      Q 308.00 382.42 296.76 368.65
                      C 295.97 367.68 296.24 365.88 297.73 365.75
                      Q 325.08 363.20 347.37 353.49
                      Q 387.04 336.20 400.56 296.24
                      Q 410.66 266.40 407.83 232.84
                      Q 405.75 208.22 391.71 187.99
                      Q 388.06 182.73 384.04 177.68
                      Q 383.14 176.55 383.53 175.23
                      Q 393.10 143.34 381.32 112.24
                      C 380.68 110.56 380.21 109.20 378.03 109.27
                      C 354.14 110.01 333.25 121.85 313.65 134.78
                      A 4.33 4.31 34.0 0 1 310.43 135.40
                      C 301.15 133.50 291.44 131.06 282.51 129.82
                      Q 236.18 123.39 190.18 135.19
                      C 188.63 135.59 187.05 135.73 185.64 134.78
                      C 173.35 126.46 159.44 118.94 145.36 113.68
                      Q 137.14 110.60 125.72 109.50
                      C 122.39 109.18 119.04 109.60 117.66 113.12
                      Q 105.95 143.09 115.44 174.07
                      C 115.81 175.27 115.71 176.48 114.90 177.48
                      Q 105.07 189.74 99.26 202.47
                      Q 93.03 216.12 91.39 233.70
                      C 89.61 252.85 91.63 272.98 97.11 291.48
                      C 103.07 311.60 114.18 328.69 130.75 341.24
                      C 150.73 356.39 176.30 363.62 201.33 366.11
                      Q 201.97 366.18 202.55 366.50
                      A 0.96 0.95 -56.1 0 1 202.83 367.94
                      Q 191.47 381.80 189.07 398.89
                      Q 188.88 400.26 187.64 400.77
                      Q 177.44 404.97 166.43 406.07
                      Q 139.20 408.77 122.48 388.99
                      C 118.26 383.99 114.85 377.78 110.52 372.67
                      Q 101.18 361.64 89.74 356.50
                      Q 78.75 351.56 68.61 355.76
                      Q 67.27 356.31 66.33 357.40
                      Q 65.92 357.88 66.25 358.43
                      C 69.51 363.77 74.06 367.18 78.88 370.79
                      C 86.07 376.18 92.48 383.66 96.84 391.28
                      C 100.98 398.53 103.65 406.78 108.00 413.74
                      C 124.61 440.34 157.44 446.44 186.57 438.66
                      A 1.17 1.17 0.0 0 1 188.04 439.80
                      Q 187.89 458.65 188.09 478.60
                      Q 188.15 484.34 186.75 487.05
                      C 183.25 493.82 174.78 494.83 168.09 492.41
                      Q 124.90 476.83 90.49 448.74
                      C 56.76 421.21 31.69 384.75 15.59 343.87
                      C 8.65 326.26 4.13 307.03 1.80 288.50
                      Q 0.51 278.24 0.00 274.87
                      L 0.00 242.00
                      Q 0.62 235.70 2.20 223.53
                      C 7.92 179.38 24.68 138.32 51.86 103.56
                      Q 101.86 39.61 180.22 16.08
                      Q 203.23 9.18 228.97 7.25
                      Q 265.61 4.49 301.40 11.58
                      C 351.75 21.55 397.75 48.08 433.35 85.56
                      C 470.53 124.71 492.30 175.25 498.41 229.01
                      Q 499.14 235.45 500.00 241.88
                      Z"></path>
                  </svg>
                  </div>
                  <div>GitHub</div>
                </a>
              </div>
              <div className="w-layout-grid footer-social-grid">
                <a href="https://notion.mazze.io/" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 519.00">
                    <path fill="#a3a7bf" d="
                      M 242.89 0.00
                      L 257.48 0.00
                      Q 311.93 2.06 358.27 25.52
                      C 438.44 66.12 489.67 144.33 498.75 233.51
                      Q 499.22 238.15 500.00 251.63
                      L 500.00 267.50
                      Q 499.37 278.66 498.74 285.25
                      C 491.62 359.11 454.15 427.48 394.38 471.37
                      Q 333.73 515.90 257.38 519.00
                      L 242.52 519.00
                      Q 182.36 516.71 131.99 488.33
                      Q 94.13 467.01 66.83 436.16
                      Q 7.82 369.49 0.90 278.98
                      Q 0.45 273.18 0.00 267.37
                      L 0.00 251.25
                      Q 0.49 244.56 1.05 237.89
                      Q 8.12 152.87 61.85 88.60
                      Q 93.14 51.17 137.79 27.54
                      Q 185.94 2.06 242.89 0.00
                      Z
                      M 169.50 433.73
                      C 244.27 429.37 319.37 424.76 393.63 420.31
                      C 407.33 419.48 417.97 413.79 417.97 398.15
                      Q 417.97 276.49 417.98 155.33
                      C 417.98 148.36 411.83 144.46 406.64 140.83
                      Q 371.19 115.94 336.14 91.58
                      Q 331.19 88.13 324.82 86.31
                      C 318.65 84.55 310.29 84.85 303.65 85.33
                      Q 202.20 92.70 102.99 100.03
                      Q 88.02 101.14 83.60 113.49
                      Q 81.97 118.03 81.98 124.96
                      Q 82.04 236.49 82.03 336.34
                      C 82.03 345.67 86.20 354.45 91.88 361.86
                      Q 114.02 390.72 139.42 423.37
                      Q 141.62 426.19 144.55 428.63
                      C 151.60 434.49 160.68 434.24 169.50 433.73
                      Z"></path>
                    <path fill="#a3a7bf" d="
                      M 369.28 141.34
                      C 299.38 145.59 232.44 149.57 163.50 153.62
                      C 156.16 154.05 149.58 153.55 143.00 148.48
                      Q 129.50 138.09 113.63 125.39
                      Q 111.95 124.05 111.81 122.13
                      Q 111.77 121.61 112.10 121.21
                      C 114.35 118.49 117.64 118.34 120.78 118.12
                      Q 207.45 111.88 305.47 104.69
                      C 315.29 103.97 324.01 105.34 331.96 110.98
                      Q 347.56 122.04 367.88 136.74
                      Q 369.39 137.84 370.30 139.46
                      A 1.26 1.26 0.0 0 1 369.28 141.34
                      Z"></path>
                    <path fill="#a3a7bf" d="
                      M 166.66 177.12
                      Q 274.94 170.73 383.22 164.51
                      Q 387.09 164.29 388.73 164.84
                      C 393.61 166.47 395.00 171.99 395.03 176.57
                      Q 395.43 239.27 395.09 303.25
                      C 394.96 326.60 394.97 351.03 395.08 377.01
                      C 395.11 384.81 394.50 391.49 387.10 394.22
                      Q 384.84 395.05 378.80 395.39
                      Q 276.97 401.24 173.25 407.26
                      C 168.49 407.53 162.83 408.08 158.56 406.27
                      Q 151.55 403.30 151.53 394.25
                      Q 151.31 289.09 151.65 200.50
                      Q 151.67 194.82 151.73 189.18
                      Q 151.77 184.85 152.64 182.98
                      C 155.24 177.40 161.24 177.44 166.66 177.12
                      Z
                      M 231.81 257.77
                      Q 231.81 255.19 233.20 257.37
                      Q 265.63 308.10 296.56 356.41
                      C 299.10 360.37 301.80 363.88 305.02 367.35
                      C 315.36 378.52 332.92 372.39 344.02 366.31
                      Q 344.50 366.05 344.50 365.50
                      L 344.49 217.28
                      Q 344.49 216.78 344.98 216.66
                      Q 349.85 215.56 354.77 214.71
                      C 361.32 213.58 362.10 207.80 361.05 202.24
                      A 0.50 0.49 -7.2 0 0 360.53 201.84
                      Q 340.21 203.02 314.43 204.49
                      C 307.27 204.89 302.13 210.01 302.70 217.42
                      A 0.80 0.79 0.9 0 0 303.41 218.15
                      L 320.02 220.00
                      A 0.59 0.58 3.3 0 1 320.54 220.58
                      Q 320.52 266.14 320.60 312.00
                      Q 320.60 312.77 319.93 312.54
                      A 0.51 0.42 -1.4 0 1 319.69 312.35
                      L 251.28 208.25
                      Q 251.08 207.95 250.73 207.97
                      Q 224.52 209.64 200.35 211.37
                      C 192.33 211.94 188.03 218.62 188.76 226.19
                      A 0.78 0.77 89.5 0 0 189.47 226.90
                      L 205.52 228.23
                      Q 206.03 228.27 206.03 228.77
                      L 206.02 361.51
                      Q 206.02 362.02 205.53 362.16
                      Q 200.75 363.46 195.57 364.81
                      C 190.14 366.22 188.06 369.68 188.56 375.07
                      Q 188.67 376.23 189.83 376.16
                      Q 212.54 374.76 235.15 373.61
                      C 243.79 373.17 251.04 371.46 253.12 362.29
                      A 0.99 0.98 -77.4 0 0 252.38 361.11
                      L 232.42 356.68
                      Q 231.81 356.55 231.81 355.92
                      L 231.81 257.77
                      Z"></path>
                  </svg>
                  </div>
                  <div>Notion</div>
                </a>
                <a href="https://mazzelabs.medium.com/" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                    <path fill="#a3a7bf" d="
                      M 236.88 0.00
                      L 263.00 0.00
                      Q 277.91 1.22 285.88 2.38
                      C 347.81 11.36 405.07 43.65 444.18 92.07
                      Q 496.43 156.76 500.00 241.37
                      L 500.00 257.00
                      Q 498.62 278.97 497.28 287.51
                      Q 487.11 352.42 446.73 404.01
                      Q 416.97 442.03 376.26 465.83
                      Q 321.72 497.71 257.35 500.00
                      L 242.52 500.00
                      Q 189.62 498.13 142.83 475.93
                      C 83.07 447.58 36.73 396.14 14.33 333.61
                      C 14.13 333.05 14.37 332.50 14.13 331.83
                      Q 3.54 301.69 0.97 270.27
                      Q 0.96 270.17 1.06 270.16
                      L 1.06 270.16
                      Q 1.19 270.14 1.21 270.27
                      Q 7.37 313.08 38.21 341.68
                      C 67.23 368.57 105.41 379.91 145.02 375.02
                      Q 181.35 370.53 210.79 345.96
                      Q 247.43 315.39 253.49 266.67
                      Q 254.63 257.54 253.98 247.21
                      C 250.91 198.34 221.42 159.20 176.36 139.96
                      C 173.57 138.77 170.64 138.52 167.83 137.09
                      Q 165.70 136.01 163.36 135.42
                      C 144.89 130.78 126.35 129.19 107.79 131.77
                      Q 78.19 135.89 51.67 154.14
                      C 22.92 173.90 5.06 204.90 0.67 239.63
                      Q 0.65 239.82 0.46 239.80
                      L 0.45 239.80
                      Q 0.26 239.79 0.27 239.60
                      C 2.79 158.21 46.50 83.63 114.41 39.68
                      Q 164.35 7.37 224.53 1.32
                      Q 230.69 0.70 236.88 0.00
                      Z
                      M 330.0500 369.0200
                      A 115.58 63.51 90.0 0 0 393.5600 253.4400
                      A 115.58 63.51 90.0 0 0 330.0500 137.8600
                      A 115.58 63.51 90.0 0 0 266.5400 253.4400
                      A 115.58 63.51 90.0 0 0 330.0500 369.0200
                      Z
                      M 447.10 309.88
                      C 448.22 299.54 449.29 289.60 450.64 279.79
                      Q 451.00 277.16 450.99 274.71
                      Q 450.92 256.25 451.04 237.09
                      Q 451.09 229.37 450.02 221.13
                      Q 448.95 212.85 448.10 204.57
                      C 447.05 194.30 445.27 183.41 442.76 173.30
                      Q 439.72 161.06 433.52 152.63
                      C 431.15 149.41 426.79 149.07 423.94 151.91
                      C 420.27 155.57 418.03 160.14 416.54 165.29
                      C 412.64 178.81 409.83 191.77 408.33 205.60
                      Q 402.46 259.57 409.98 312.04
                      Q 411.94 325.71 414.91 336.86
                      C 416.73 343.66 419.25 349.95 424.12 355.25
                      Q 425.83 357.12 428.30 356.68
                      Q 434.17 355.64 436.70 349.74
                      Q 444.83 330.83 447.10 309.88
                      Z"></path>
                  </svg>
                  </div>
                  <div>Medium</div>
                </a>
                <a href="https://discord.mazze.io/" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                    <path fill="#a3a7bf" d="
                      M 242.88 0.00
                      L 257.26 0.00
                      Q 356.54 4.43 426.02 72.34
                      Q 433.22 79.38 439.69 86.94
                      Q 496.08 152.90 500.00 241.38
                      L 500.00 256.75
                      Q 499.47 266.53 498.42 276.24
                      C 491.71 338.34 461.80 395.28 414.92 436.25
                      Q 414.67 436.47 414.78 436.79
                      Q 414.84 436.96 414.98 437.01
                      Q 415.68 437.28 415.06 437.82
                      Q 347.41 496.29 257.39 500.00
                      L 242.49 500.00
                      Q 152.41 496.24 84.75 437.66
                      Q 84.51 437.44 84.75 437.23
                      Q 84.87 437.12 84.97 437.07
                      Q 85.67 436.76 85.08 436.24
                      Q 44.77 400.76 22.80 353.21
                      Q 1.89 307.93 0.00 256.85
                      L 0.00 241.26
                      Q 3.82 152.91 60.19 86.97
                      Q 92.14 49.59 137.64 26.68
                      Q 182.46 4.12 232.63 0.73
                      Q 237.75 0.39 242.88 0.00
                      Z
                      M 349.36 328.75
                      Q 350.87 329.67 352.28 330.73
                      Q 355.37 333.06 351.95 334.88
                      Q 339.29 341.64 324.20 347.06
                      A 1.95 1.95 0.0 0 0 323.07 349.67
                      Q 323.80 351.36 324.76 353.02
                      Q 332.98 367.22 342.22 379.55
                      A 1.96 1.95 -26.5 0 0 344.33 380.25
                      Q 395.33 365.10 439.43 334.03
                      A 1.91 1.91 0.0 0 0 440.23 332.69
                      Q 442.88 311.06 441.77 288.36
                      Q 438.13 214.28 401.08 151.32
                      Q 393.31 138.11 384.70 124.46
                      Q 383.84 123.10 382.35 122.45
                      Q 345.60 106.29 304.76 99.50
                      Q 303.74 99.33 303.27 100.25
                      L 294.46 117.45
                      Q 293.65 119.03 291.89 118.85
                      Q 279.89 117.62 269.87 116.85
                      Q 240.37 114.58 208.50 119.05
                      Q 206.53 119.33 205.64 117.61
                      Q 201.20 109.00 196.72 100.43
                      A 2.09 2.08 70.3 0 0 194.45 99.35
                      Q 187.24 100.84 180.24 102.35
                      Q 149.66 108.93 119.42 121.46
                      Q 117.00 122.47 116.57 123.08
                      Q 98.45 148.93 84.16 179.92
                      Q 64.94 221.61 60.17 265.49
                      C 59.21 274.25 57.93 282.83 58.00 291.44
                      C 58.07 300.28 57.68 309.27 58.55 318.14
                      Q 59.20 324.73 59.52 331.34
                      C 59.60 332.89 60.28 333.66 61.49 334.53
                      Q 105.28 365.68 156.40 380.39
                      A 1.72 1.71 -65.8 0 0 158.33 379.65
                      Q 167.20 365.58 175.97 351.71
                      C 176.89 350.25 177.89 347.90 175.54 347.05
                      Q 160.41 341.60 147.17 334.15
                      Q 145.47 333.19 146.98 331.96
                      L 151.49 328.28
                      Q 152.82 327.20 154.40 327.86
                      Q 176.90 337.38 200.39 342.51
                      Q 273.18 358.41 343.98 328.37
                      Q 346.77 327.18 349.36 328.75
                      Z"></path>
                    <ellipse fill="#a3a7bf" cx="0.00" cy="0.00" transform="translate(186.37,253.96) rotate(89.0)" rx="36.88" ry="34.25"></ellipse>
                    <ellipse fill="#a3a7bf" cx="0.00" cy="0.00" transform="translate(313.97,253.98) rotate(90.0)" rx="36.86" ry="34.31"></ellipse>
                    </svg>
                  </div>
                  <div>Discord</div>
                </a>
              </div>
              <div className="w-layout-grid footer-social-grid">
                <a href="https://docs.mazze.io/" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                    <path fill="#a3a7bf" d="
                      M 236.88 0.00
                      L 263.00 0.00
                      Q 278.13 1.24 285.89 2.37
                      C 349.58 11.61 406.71 44.72 446.68 95.06
                      Q 490.78 150.62 498.56 223.03
                      Q 499.27 229.57 500.00 236.13
                      L 500.00 262.50
                      Q 499.23 270.12 498.37 277.73
                      Q 491.51 338.28 457.74 388.79
                      Q 429.25 431.38 387.51 458.83
                      Q 328.88 497.38 257.36 500.00
                      L 242.48 500.00
                      Q 149.14 495.86 81.25 434.40
                      C 36.67 394.05 9.22 338.96 1.70 279.82
                      C 0.97 274.05 0.69 268.18 0.00 262.38
                      L 0.00 236.00
                      Q 0.51 231.68 0.86 227.34
                      C 4.81 179.22 23.18 133.72 52.73 95.90
                      C 92.67 44.78 151.06 11.16 215.50 2.18
                      Q 221.86 1.29 236.88 0.00
                      Z
                      M 198.84 347.05
                      A 2.02 2.01 -60.0 0 1 196.24 347.75
                      Q 140.87 319.40 92.99 295.52
                      C 78.96 288.51 71.83 275.54 69.92 260.04
                      Q 68.74 250.49 72.03 242.21
                      C 74.51 235.96 79.63 231.71 86.75 234.63
                      Q 91.14 236.43 95.30 238.65
                      Q 109.16 246.03 122.02 252.46
                      C 149.57 266.24 176.10 280.02 204.77 293.73
                      Q 209.14 295.82 214.09 298.65
                      C 223.32 303.93 231.46 301.97 240.45 297.46
                      Q 338.13 248.50 435.28 200.12
                      C 442.39 196.58 439.68 188.25 433.42 185.47
                      Q 365.03 155.14 296.25 123.17
                      C 285.76 118.30 275.94 116.32 265.03 121.55
                      Q 163.21 170.36 71.21 214.24
                      Q 57.56 220.74 51.40 235.73
                      Q 48.75 242.17 48.13 249.23
                      C 46.30 270.12 54.89 293.53 71.37 307.16
                      Q 76.10 311.07 88.31 317.03
                      Q 139.10 341.86 193.64 368.99
                      Q 194.02 369.18 194.23 369.56
                      C 195.28 371.42 195.50 373.83 196.30 375.84
                      C 210.50 411.51 262.76 405.42 267.97 367.11
                      C 268.15 365.84 268.61 364.67 269.81 364.05
                      Q 332.85 331.27 394.73 299.13
                      A 1.02 0.99 44.7 0 1 395.68 299.14
                      C 398.60 300.74 401.51 302.48 404.64 303.61
                      Q 423.96 310.65 440.63 299.05
                      Q 451.03 291.82 454.54 277.46
                      C 455.36 274.10 454.93 270.40 455.04 266.94
                      C 455.14 263.94 453.52 261.30 452.56 258.56
                      C 449.02 248.43 440.18 240.73 430.18 237.49
                      C 414.74 232.48 399.77 236.85 389.03 249.00
                      C 381.35 257.68 379.99 269.64 382.85 280.61
                      A 1.63 1.63 0.0 0 1 382.02 282.47
                      L 262.64 343.40
                      A 1.70 1.68 -33.5 0 1 260.58 342.99
                      C 243.57 322.67 213.20 324.96 198.84 347.05
                      Z"></path>
                    <ellipse fill="#a3a7bf" cx="418.15" cy="270.73" rx="11.62" ry="11.14"></ellipse>
                    <ellipse fill="#a3a7bf" cx="0.00" cy="0.00" transform="translate(231.15,364.19) rotate(0.2)" rx="11.55" ry="11.12"></ellipse>
                  </svg>
                  </div>
                  <div>Documentation</div>
                </a>
                <a href="https://mazze.io/whitepaperV1.pdf" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                    <path fill="#a3a7bf" d="
                      M 242.65 0.00
                      L 257.50 0.00
                      Q 355.41 4.47 424.36 70.99
                      Q 431.59 77.97 438.13 85.49
                      Q 496.08 152.19 500.00 242.39
                      L 500.00 257.75
                      Q 499.55 263.62 499.10 269.49
                      Q 495.21 320.15 471.80 365.12
                      Q 449.25 408.44 413.57 439.09
                      Q 346.88 496.36 257.37 500.00
                      L 242.50 500.00
                      Q 149.75 495.93 82.19 435.19
                      Q 74.76 428.51 67.93 421.19
                      Q 4.31 352.93 0.00 257.62
                      L 0.00 242.25
                      Q 4.01 149.64 64.80 82.04
                      Q 95.47 47.93 138.28 26.29
                      Q 186.37 1.97 242.65 0.00
                      Z
                      M 317.47 158.67
                      C 312.83 158.68 309.34 155.59 309.35 150.82
                      Q 309.42 117.51 309.33 83.24
                      A 0.39 0.39 0.0 0 0 308.89 82.85
                      Q 307.71 83.00 306.73 83.00
                      Q 224.73 82.97 147.02 82.98
                      Q 142.68 82.98 139.77 83.86
                      C 130.73 86.59 124.64 94.91 124.64 104.31
                      Q 124.60 244.10 124.63 394.56
                      C 124.63 406.41 134.00 416.02 145.85 416.02
                      Q 259.04 415.99 367.85 416.00
                      C 372.27 416.00 375.38 412.52 375.38 408.33
                      Q 375.36 281.74 375.38 159.01
                      A 0.38 0.37 -0.0 0 0 375.00 158.64
                      Q 347.71 158.59 317.47 158.67
                      Z
                      M 324.85 91.76
                      L 324.95 144.26
                      A 0.31 0.31 0.0 0 0 325.26 144.57
                      L 374.06 144.55
                      A 0.50 0.50 0.0 0 0 374.52 143.84
                      Q 372.62 139.57 368.98 135.90
                      Q 347.77 114.47 325.36 91.55
                      Q 324.85 91.02 324.85 91.76
                      Z"></path>
                    <rect fill="#a3a7bf" x="169.96" y="187.60" width="160.08" height="14.82" rx="7.33"></rect>
                    <rect fill="#a3a7bf" x="-68.09" y="-7.37" transform="translate(238.11,231.35) rotate(0.1)" width="136.18" height="14.74" rx="7.27"></rect>
                    <rect fill="#a3a7bf" x="170.00" y="259.53" width="103.66" height="14.76" rx="7.26"></rect>
                    <rect fill="#a3a7bf" x="170.02" y="295.85" width="88.04" height="14.82" rx="7.27"></rect>
                    </svg>
                  </div>
                  <div>Whitepaper</div>
                </a>
                <a href="https://zealy.io/c/mazze" target="_blank" className="footer-social-link w-inline-block">
                  <div className="social-logos w-embed">
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 500.00 500.00">
                    <path fill="#a3a7bf" d="
                      M 242.90 0.00
                      L 257.25 0.00
                      Q 263.37 0.43 269.49 0.90
                      Q 318.77 4.64 362.27 26.50
                      C 394.66 42.78 423.63 66.10 446.16 94.69
                      Q 496.49 158.57 500.00 241.38
                      L 500.00 262.75
                      Q 499.21 270.37 498.33 277.98
                      Q 490.56 345.42 450.05 399.61
                      Q 420.56 439.08 379.43 463.94
                      Q 323.71 497.62 257.35 500.00
                      L 242.48 500.00
                      Q 149.91 495.93 82.29 435.33
                      C 37.45 395.16 8.75 339.03 1.63 279.45
                      Q 1.12 275.17 0.75 268.05
                      Q 0.45 262.33 0.00 256.62
                      L 0.00 241.24
                      Q 4.12 148.25 65.71 80.73
                      Q 94.02 49.69 132.99 28.90
                      Q 183.42 2.01 242.90 0.00
                      Z
                      M 106.79 238.36
                      Q 106.72 238.60 106.48 239.04
                      Q 106.31 239.36 105.98 239.56
                      Q 89.19 249.68 73.76 259.05
                      A 1.42 1.41 74.6 0 0 73.08 260.26
                      L 73.04 336.04
                      A 3.92 3.89 -68.2 0 0 74.24 338.86
                      Q 107.49 371.04 141.40 403.99
                      Q 142.99 405.54 145.13 405.67
                      C 193.45 408.71 241.91 407.90 290.30 403.00
                      Q 357.34 396.20 422.02 375.28
                      A 1.41 1.39 81.2 0 0 422.99 373.94
                      L 422.99 281.52
                      A 2.66 2.64 -68.2 0 0 422.18 279.61
                      L 398.55 256.69
                      Q 397.44 255.61 398.55 254.54
                      L 416.79 237.06
                      Q 417.19 236.68 417.19 236.13
                      Q 417.35 200.62 417.63 165.10
                      C 417.66 160.96 417.06 158.02 414.23 155.28
                      Q 380.78 123.02 347.60 90.83
                      Q 347.23 90.46 346.73 90.62
                      Q 313.71 101.24 281.36 107.54
                      Q 272.01 109.37 260.99 111.31
                      Q 250.21 113.20 241.08 114.37
                      Q 187.65 121.21 131.18 121.31
                      Q 121.59 121.33 110.75 120.87
                      Q 97.79 120.33 84.51 119.63
                      Q 84.09 119.61 83.79 119.64
                      A 1.36 1.36 0.0 0 0 82.57 120.99
                      L 82.55 213.13
                      A 2.10 2.10 0.0 0 0 83.20 214.65
                      L 106.45 237.03
                      A 1.31 1.31 0.0 0 1 106.79 238.36
                      Z"></path>
                    <path fill="#a3a7bf" d="
                      M 261.16 336.63
                      Q 259.92 337.56 261.46 337.46
                      Q 329.90 332.92 395.33 315.09
                      A 0.96 0.96 0.0 0 1 396.55 316.02
                      L 396.56 356.23
                      A 1.43 1.42 -7.8 0 1 395.51 357.60
                      Q 342.79 372.13 289.00 377.63
                      Q 231.95 383.47 170.13 381.18
                      A 0.30 0.30 0.0 0 1 169.84 380.88
                      L 169.84 343.48
                      A 1.84 1.83 -16.0 0 1 170.71 341.92
                      Q 234.90 302.46 297.65 254.76
                      Q 298.46 254.15 299.15 253.48
                      Q 300.31 252.35 298.70 252.53
                      Q 239.92 259.27 180.42 257.15
                      A 0.93 0.93 0.0 0 1 179.52 256.22
                      L 179.52 215.77
                      Q 179.52 214.69 180.60 214.73
                      C 250.98 216.92 321.48 210.38 389.07 192.69
                      Q 390.75 192.25 390.75 193.99
                      Q 390.76 210.55 390.76 228.68
                      C 390.76 230.14 390.32 230.98 389.22 232.01
                      Q 345.36 272.96 294.53 311.50
                      Q 277.87 324.14 261.16 336.63
                      Z"></path>
                    </svg>
                  </div>
                  <div>Zealy</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}