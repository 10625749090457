type BlockTransactionsProps = {
    item: any
}

export const BlockTransactions = ({ item }: BlockTransactionsProps) => {

    return (
        <div className="cards">
            Hello Transactions
        </div>
    )
}